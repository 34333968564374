import React from "react";
import { TextInput } from "react-admin";
import { MyEdit } from "../../_myBase/components/myMutator";
import { UserInput } from "../users/fields";
import { PackageStatusInput } from "../package_statuses/fields";

export default function PackageEdit(props) {
  return (
    <MyEdit {...props}>
      <TextInput disabled label="Id" source="id"></TextInput>
      <TextInput source="name"></TextInput>
      <UserInput label="sender" source="senderId" />
      <UserInput label="recipient" source="recipientId" allowEmpty />
      <PackageStatusInput label="status" source="statusId" />
    </MyEdit>
  );
}
