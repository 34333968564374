import { TextField } from "react-admin";
import { MyList } from "../../_myBase/components/myList";

export default function FeedBacksList(props) {
  return (
    <MyList resource="feedbacks" {...props}>
      <TextField source="id" />
      <TextField source="score" />
      <TextField source="feedbackText" />
      <TextField source="createdAt" />
    </MyList>
  );
}
