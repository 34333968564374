import React from "react";
import {
  TextField,
  BooleanField,
  DateField,
  NumberField,
  TextInput,
  BooleanInput,
} from "react-admin";
import { MyList } from "../../_myBase/components/myList";
import useFieldPreferences from "../../hooks/useFieldPreferences";

const allFields = [
  { name: "id", component: TextField, props: { sortable: true } },
  { name: "firstName", component: TextField, props: { sortable: false } },
  { name: "lastName", component: TextField, props: { sortable: false } },
  { name: "email", component: TextField, props: { sortable: true } },
  {
    name: "emailValidated",
    component: BooleanField,
    props: { sortable: false },
  },
  { name: "isActive", component: BooleanField, props: { sortable: false } },
  { name: "isSuperuser", component: BooleanField, props: { sortable: false } },
  { name: "isHost", component: BooleanField, props: { sortable: false } },
  { name: "phoneNumber", component: TextField, props: { sortable: true } },
  {
    name: "phoneValidated",
    component: BooleanField,
    props: { sortable: false },
  },
  {
    name: "createdAt",
    component: DateField,
    props: { label: "Signed Up", sortable: true },
  },
  { name: "pickups", component: NumberField, props: { sortable: false } },
  { name: "dropoffs", component: NumberField, props: { sortable: false } },
];

const userFilters = [
  <TextInput label="Email" source="email" alwaysOn key={1} />,
  <TextInput label="Phone" source="phone_number" alwaysOn key={2} />,
  <BooleanInput source="email_validated" alwaysOn key={3} />,
  <BooleanInput source="phone_validated" alwaysOn key={4} />,
];

export default function UsersList(props) {
  const { visibleFields, loading, updatePreference } = useFieldPreferences(
    "users",
    allFields.map((field) => field.name)
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  const filteredFields = allFields.filter((field) =>
    visibleFields.includes(field.name)
  );

  return (
    <MyList
      resource="users"
      {...props}
      filters={userFilters}
      fields={allFields.map((field) => field.name)}
      onPreferencesUpdate={updatePreference}
    >
      {filteredFields.map(
        ({ name, component: Component, props: fieldProps }) => (
          <Component key={name} source={name} {...fieldProps} />
        )
      )}
    </MyList>
  );
}
