import React from "react";
import { TextField, TabbedShowLayout, NumberField } from "react-admin";
import {
  LabeledColumn,
  TitledShow,
} from "../../_myBase/components/titledRecord";
import { Stack } from "@mui/material";

export default function HostTowersShow() {
  return (
    <TitledShow>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Summary">
          <Stack spacing={4} direction="row">
            <LabeledColumn>
              <TextField source="id" />
              <NumberField source="userId" />
              <TextField source="towerId" />
            </LabeledColumn>
          </Stack>
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </TitledShow>
  );
}
