import { AppSettings } from "../components/appSettings";
import SettingsIcon from "@mui/icons-material/Settings";

export const useCustomRoutes = () => [
  {
    path: "/app-settings",
    component: <AppSettings />,
    routeProps: {},
    onMenu: true,
    label: "Settings",
    menuProps: {
      leftIcon: <SettingsIcon />,
    },
    options: {
      sortOrder: 100,
    },
  },
];

export const menuGroups = {
  "": null,
  legacy: null,
  debug: null,
};
