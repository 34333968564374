import React from "react";
import { TextInput, NumberInput, BooleanInput } from "react-admin";
import { MyCreate } from "../../_myBase/components/myMutator";

export default function PricingPlanCreate(props) {
  return (
    <MyCreate {...props}>
      <TextInput source="name"></TextInput>
      <NumberInput source="small"></NumberInput>
      <NumberInput source="medium"></NumberInput>
      <NumberInput source="medium_plus"></NumberInput>
      <NumberInput source="large"></NumberInput>
      <NumberInput source="extra_large"></NumberInput>
      <BooleanInput source="isDefault"></BooleanInput>
    </MyCreate>
  );
}
