import React from "react";
import { TextField } from "react-admin";
import { MyList } from "../../_myBase/components/myList";
import { PackageStatusField } from "../package_statuses/fields";

/**
 * Unused ???
 * @param {} props
 * @returns
 */
export default function PackageHistoryList(props) {
  return (
    <MyList
      resource="packages-history"
      sort={{
        field: "id",
        order: "DESC",
      }}
      {...props}
    >
      <TextField source="id" />
      <TextField source="packageId" />
      <PackageStatusField source="statusId" />
      <TextField source="description" />
      <TextField source="timestamp" />
    </MyList>
  );
}
