import React from "react";
import { TextField, TextInput } from "react-admin";
import { MyList } from "../../_myBase/components/myList";
import { MyReferenceField } from "../../_myBase/components/myReference";
import useFieldPreferences from "../../hooks/useFieldPreferences";

const allFields = [
  { name: "id", component: TextField, props: { sortable: true } },
  { name: "name", component: TextField, props: { sortable: true } },
  { name: "address1", component: TextField, props: { sortable: true } },
  { name: "lat", component: TextField, props: { sortable: true } },
  { name: "lon", component: TextField, props: { sortable: true } },
  {
    name: "pricingPlanId",
    component: MyReferenceField,
    props: {
      label: "Pricing Plan",
      reference: "pricing-plans",
      sortable: false,
    },
  },
];

const locationFilters = [
  <TextInput label="Search by Location Name" source="name" alwaysOn key={1} />,
];

export default function LocationList(props) {
  const { visibleFields, loading, updatePreference } = useFieldPreferences(
    "locations",
    allFields.map((field) => field.name)
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  const filteredFields = allFields.filter((field) =>
    visibleFields.includes(field.name)
  );

  return (
    <MyList
      resource="locations"
      {...props}
      filters={locationFilters}
      fields={allFields.map((field) => field.name)}
      onPreferencesUpdate={updatePreference}
    >
      {filteredFields.map(
        ({ name, component: Component, props: fieldProps }) => (
          <Component key={name} source={name} {...fieldProps} />
        )
      )}
    </MyList>
  );
}
