import React from "react";
import { TextInput } from "react-admin";
import { MyCreate } from "../../_myBase/components/myMutator";

export default function PackageStatusesCreate(props) {
  return (
    <MyCreate {...props}>
      <TextInput source="name"></TextInput>
      <TextInput source="description"></TextInput>
    </MyCreate>
  );
}
