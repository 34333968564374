import React from "react";
import {
  TextField,
  DateField,
  SelectInput,
  TextInput,
  DateInput,
  BulkDeleteButton,
} from "react-admin";

import { MyList } from "../../_myBase/components/myList";
import { PackageStatusField } from "../package_statuses/fields";
import { UserField } from "../users/fields";
import BulkUpdateStatusButton from "./BulkUpdateStatusButton";
import useFieldPreferences from "../../hooks/useFieldPreferences";

const statusChoices = [
  { id: 1, name: "In Locker" },
  { id: 2, name: "Picked Up" },
  { id: 3, name: "Dropoff Pending" },
  { id: 4, name: "Dropoff Canceled" },
  { id: 5, name: "Dropoff Cancel Pending" },
];

const allFields = [
  { name: "id", component: TextField, props: { sortable: true } },
  { name: "name", component: TextField, props: { sortable: true } },
  {
    name: "statusId",
    component: PackageStatusField,
    props: { sortable: true },
  },
  { name: "towerId", component: TextField, props: { sortable: true } },
  { name: "lockerType", component: TextField, props: { sortable: true } },
  {
    name: "lockerId",
    component: TextField,
    props: { sortable: true, label: "Locker id" },
  },
  { name: "lockerName", component: TextField, props: { sortable: true } },
  { name: "locationName", component: TextField, props: { sortable: true } },
  { name: "senderId", component: UserField, props: { sortable: true } },
  { name: "recipientId", component: UserField, props: { sortable: true } },
  {
    name: "dropoffTime",
    component: DateField,
    props: { showTime: true, sortable: true },
  },
  {
    name: "pickupTime",
    component: DateField,
    props: { showTime: true, sortable: true },
  },
];

const packageFilters = [
  <TextInput label="Sender email" source="sender__email" alwaysOn key={1} />,
  <TextInput
    label="Recipient email"
    source="recipient__email"
    alwaysOn
    key={2}
  />,
  <SelectInput source="status_id" choices={statusChoices} alwaysOn key={3} />,
  <DateInput label="Dropoff Start" source="dropoffTime_gte" alwaysOn key={4} />,
  <DateInput label="Dropoff End" source="dropoffTime_lte" alwaysOn key={5} />,
];

const PostBulkActionButtons = () => (
  <>
    <BulkUpdateStatusButton resource="packages" statusChoices={statusChoices} />
    <BulkDeleteButton />
  </>
);

export default function PackagesList(props) {
  const { visibleFields, loading, updatePreference } = useFieldPreferences(
    "packages",
    allFields.map((field) => field.name)
  );

  if (loading) {
    return <div>Loading...</div>;
  }
  const filteredFields = allFields.filter((field) =>
    visibleFields.includes(field.name)
  );

  return (
    <MyList
      resource="packages"
      {...props}
      filters={packageFilters}
      bulkActionButtons={<PostBulkActionButtons />}
      fields={allFields.map((field) => field.name)}
      onPreferencesUpdate={updatePreference}
    >
      {filteredFields.map(
        ({ name, component: Component, props: fieldProps }) => (
          <Component key={name} source={name} {...fieldProps} />
        )
      )}
    </MyList>
  );
}
