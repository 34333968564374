import { colors } from "@mui/material";
import { red } from "@mui/material/colors";

export const debugInfo = {
  color: colors["purple"]["400"] + "!important",
  "*": {
    color: colors["purple"]["400"] + "!important",
  },
};

export const customRouteMenu = {
  textTransform: "capitalize",
};

export const labeledBig = {};

export const sxSpot = (props) => {
  props = props || {};
  const size = props.size || 25;

  return {
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: size,
    width: size,
    height: size,
    backgroundColor: props.bg || red[400],
    color: props.color || "white",
  };
};
