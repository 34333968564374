import { Box } from "@mui/material";
import { useState } from "react";
import { Menu } from "react-admin";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import capitalize from "lodash/capitalize";

export const AccordionMenu = (props) => {
  const [open, setOpen] = useState(props.open);
  const Icon = open ? ExpandLessIcon : ExpandMoreIcon;

  return (
    <Box>
      <Menu.Item
        to={window.location.href.split("#").pop()}
        onClick={() => setOpen(!open)}
        primaryText={capitalize(props.title)}
        leftIcon={<Icon />}
      >
        {capitalize(props.title)}
      </Menu.Item>
      {open && <Box sx={{ padding: "0 16px" }}>{props.children}</Box>}
    </Box>
  );
};

AccordionMenu.defaultValues = {
  open: true,
};
