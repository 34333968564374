import React from "react";
import {
  NumberInput,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
} from "react-admin";
import { MyCreate } from "../../_myBase/components/myMutator";

export default function HostTowersCreate(props) {
  return (
    <MyCreate {...props}>
      <NumberInput source="user_id" />
      <ArrayInput source="tower_ids">
        <SimpleFormIterator>
          <TextInput source="" label="Tower ID" />
        </SimpleFormIterator>
      </ArrayInput>
    </MyCreate>
  );
}
