import React, { useState, useCallback, useEffect } from "react";
import { Button, useDataProvider, useNotify } from "react-admin";
import { ViewColumn, Search } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Typography,
  Box,
  TextField,
  Fade,
  Divider,
  IconButton,
  styled,
} from "@mui/material";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogTitle-root": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
}));

const SearchBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
}));

const FieldList = styled(List)(({ theme }) => ({
  maxHeight: "50vh",
  overflowY: "auto",
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
}));

const FieldPreferencesButton = ({
  resource,
  fields = [],
  onPreferencesUpdate,
}) => {
  const [open, setOpen] = useState(false);
  const [visibleFields, setVisibleFields] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const dataProvider = useDataProvider();
  const notify = useNotify();

  useEffect(() => {
    setVisibleFields(fields);
  }, [fields]);

  const handleOpen = useCallback(() => {
    setOpen(true);
    if (fields.length === 0) {
      notify("No fields available for preferences", "warning");
      return;
    }
    dataProvider
      .getOne("user-preferences", { id: resource })
      .then(({ data }) => {
        const hiddenFields = data.hiddenFields || [];
        setVisibleFields(
          fields.filter((field) => !hiddenFields.includes(field))
        );
      })
      .catch(() => {
        notify("Error fetching preferences", "error");
      });
  }, [dataProvider, fields, notify, resource]);

  const handleClose = () => {
    setOpen(false);
    setSearchTerm("");
  };

  const handleToggle = (field) => {
    setVisibleFields((prev) =>
      prev.includes(field) ? prev.filter((f) => f !== field) : [...prev, field]
    );
  };

  const handleSave = async () => {
    const hiddenFields = fields.filter(
      (field) => !visibleFields.includes(field)
    );
    try {
      handleClose();
      onPreferencesUpdate(hiddenFields);
    } catch (error) {
      notify("Error updating preferences", "error");
    }
  };

  const filteredFields = fields.filter((field) =>
    field.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (fields.length === 0) {
    return null;
  }

  return (
    <>
      <Button
        onClick={handleOpen}
        label="Customize Columns"
        startIcon={<ViewColumn />}
      />
      <StyledDialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Fade}
        transitionDuration={300}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <ViewColumn sx={{ mr: 1 }} />
            <Typography variant="h6">
              Customize Columns for {resource}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <SearchBox>
            <IconButton size="small">
              <Search />
            </IconButton>
            <TextField
              fullWidth
              variant="standard"
              placeholder="Search columns..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                disableUnderline: true,
              }}
            />
          </SearchBox>
          <Typography variant="body2" color="textSecondary" paragraph>
            Select the columns you want to display in the list view:
          </Typography>
          <FieldList>
            {filteredFields.map((field, index) => (
              <React.Fragment key={field}>
                <ListItem dense button onClick={() => handleToggle(field)}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={visibleFields.includes(field)}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText primary={field} />
                </ListItem>
                {index < filteredFields.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </FieldList>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Button
            onClick={handleClose}
            color="secondary"
            variant="outlined"
            sx={{
              textTransform: "capitalize",
              textAlign: "center",
              paddingLeft: 2,
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            sx={{
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
              textTransform: "capitalize",
              textAlign: "center",
              paddingLeft: 2,
            }}
          >
            Save Changes
          </Button>
        </DialogActions>
      </StyledDialog>
    </>
  );
};
export default FieldPreferencesButton;
