import React from "react";
import { TextField, TabbedShowLayout } from "react-admin";
import {
  LabeledColumn,
  TitledShow,
} from "../../_myBase/components/titledRecord";
import { Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import PackagesList from "../packages/PackagesList";

export default function PackageStatusesShow() {
  const id = parseInt(useParams()["id"]);

  return (
    <TitledShow>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Summary">
          <Stack spacing={4} direction="row">
            <LabeledColumn>
              <TextField source="id" />
              <TextField source="name" />
              <TextField source="description" />
            </LabeledColumn>
          </Stack>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Packages" path="packages">
          <PackagesList filter={{ statusId: id }} />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </TitledShow>
  );
}
