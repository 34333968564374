import React from "react";
import { Show, SimpleShowLayout, TextField } from "react-admin";
import { UserField } from "../users/fields";

export default function AddressShow(props) {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <UserField source="ownerId" />
        <TextField source="address1" />
        <TextField source="address2" />
        <TextField source="zipcode" />
        <TextField source="country" />
        <TextField source="addressType" />
      </SimpleShowLayout>
    </Show>
  );
}
