import React from "react";
import { TextInput } from "react-admin";
import { MyEdit } from "../../_myBase/components/myMutator";
import { UserInput } from "../users/fields";
import { LocationInput } from "../locations/fields";

export default function FavoriteLocationsEdit(props) {
  return (
    <MyEdit {...props}>
      <TextInput disabled label="Id" source="id"></TextInput>
      <UserInput source="userId" />
      <LocationInput source="locationId" />
    </MyEdit>
  );
}
