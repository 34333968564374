import { Box, FormControlLabel, Switch, TextField } from "@mui/material";

const LabeledSwitch = (props) => {
  const control = <Switch checked={props.value} onChange={props.onChange} />;

  return <FormControlLabel control={control} label={props.label} />;
};

const NumberField = (props) => <TextField type="number" {...props} />;

const fieldInputs = {
  default: {
    input: TextField,
    getter: (event) => event.target.value,
    parser: (v) => v,
  },
  boolean: {
    input: LabeledSwitch,
    getter: (event) => event.target.checked,
  },
  number: {
    input: NumberField,
    parser: parseInt,
  },
};

const getFieldInput = (fieldSetup) => {
  const type = typeof fieldSetup.value;
  const typeSetup = Object.assign(
    {},
    fieldInputs.default,
    fieldInputs[type] || {}
  );
  const Input = typeSetup.input;

  const handler = (event) => {
    const value = typeSetup.parser(typeSetup.getter(event));
    fieldSetup.setter(value);
  };

  return (
    <Box key={fieldSetup.name}>
      <Input
        label={fieldSetup.label}
        onChange={handler}
        value={fieldSetup.value}
      />
    </Box>
  );
};

export const getUnsourcedInputs = (setups) => {
  return Object.values(setups).map((setup) => getFieldInput(setup));
};
