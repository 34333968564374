import React from "react";
import { TextField, ImageField } from "react-admin";
import { MyList } from "../../_myBase/components/myList";

export default function PackagePicturesList(props) {
  return (
    <MyList resource="packages-pictures" {...props}>
      <TextField source="id" />
      <ImageField source="thumbnailUrl" title="thumbnail" />
      <TextField source="pictureType" />
      <TextField source="packageId" />
    </MyList>
  );
}
