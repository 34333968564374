import { Layout, defaultTheme, Menu } from "react-admin";
import { createTheme } from "@mui/material";
import { blue, grey, yellow, orange, red } from "@mui/material/colors";
import { alpha } from "@mui/material/styles";
import { ResourceMenuItems } from "../utils/resourceMenuItems";

const RaLabeled = {
  styleOverrides: {
    root: {
      marginBottom: ".8em",
      "& .RaLabeled-label": {
        color: "#1976d2",
        fontWeight: "bolder",
        fontSize: "1.25em",
      },
      "& .MuiTypography-body2": {
        fontSize: "1em",
      },
    },
  },
};

export const jiTheme = createTheme({
  ...defaultTheme,
  palette: {
    // type: 'dark',
    primary: {
      main: blue[700],
    },
    secondary: {
      main: blue[900],
    },
    background: {
      paper: grey[100],
      default: grey[200],
    },
    specialColors: {
      testing: alpha(orange[600], 0.3),
      oos: alpha(red[600], 0.3),
      highlight: alpha(yellow[100], 0.7),
    },
  },
  shape: {
    borderRadius: 0,
  },
  components: {
    ...defaultTheme.components,
    RaLabeled,
  },
});

const MyMenu = () => (
  <Menu>
    <Menu.DashboardItem />
    <ResourceMenuItems />
  </Menu>
);

const MyLayout = (props) => <Layout {...props} menu={MyMenu} />;

export default MyLayout;
