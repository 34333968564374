import React from "react";
import { TextInput, NumberInput, BooleanInput } from "react-admin";
import { MyEdit } from "../../_myBase/components/myMutator";

export default function PricingPlanEdit(props) {
  return (
    <MyEdit {...props}>
      <TextInput disabled label="Id" source="id"></TextInput>
      <TextInput source="name"></TextInput>
      <NumberInput source="small" />
      <NumberInput source="medium" />
      <NumberInput source="mediumPlus" />
      <NumberInput source="large" />
      <NumberInput source="extraLarge" />
      <BooleanInput source="isDefault" />
    </MyEdit>
  );
}
