import React from "react";
import { MyCreate } from "../../_myBase/components/myMutator";
import { UserInput } from "../users/fields";
import { LocationInput } from "../locations/fields";

export default function FavoriteLocationsCreate(props) {
  return (
    <MyCreate {...props}>
      <UserInput source="userId" />
      <LocationInput source="locationId" />
    </MyCreate>
  );
}
