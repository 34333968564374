import React from "react";
import { TextInput, BooleanInput } from "react-admin";
import { MyCreate } from "../../_myBase/components/myMutator";

export default function UserCreate(props) {
  return (
    <MyCreate {...props}>
      <TextInput source="first_name"></TextInput>
      <TextInput source="last_name"></TextInput>
      <TextInput source="email"></TextInput>
      <BooleanInput source="email_validated"></BooleanInput>
      <TextInput source="phone_number"></TextInput>
      <BooleanInput
        source="phone_validated"
        defaultValue="false"
      ></BooleanInput>
      <BooleanInput source="is_active"></BooleanInput>
      <BooleanInput source="is_superuser"></BooleanInput>
      <BooleanInput source="is_host"></BooleanInput>
      <TextInput source="password"></TextInput>
    </MyCreate>
  );
}
