import React from "react";
import { TextField } from "react-admin";
import { MyList } from "../../_myBase/components/myList";
import useFieldPreferences from "../../hooks/useFieldPreferences";

const allFields = [
  { name: "id", component: TextField, props: { sortable: true } },
  { name: "name", component: TextField, props: { sortable: true } },
  { name: "description", component: TextField, props: { sortable: true } },
];

export default function PackageStatusesList(props) {
  const { visibleFields, loading, updatePreference } = useFieldPreferences(
    "package-statuses",
    allFields.map((field) => field.name)
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  const filteredFields = allFields.filter((field) =>
    visibleFields.includes(field.name)
  );

  return (
    <MyList
      resource="package-statuses"
      {...props}
      fields={allFields.map((field) => field.name)}
      onPreferencesUpdate={updatePreference}
    >
      {filteredFields.map(
        ({ name, component: Component, props: fieldProps }) => (
          <Component key={name} source={name} {...fieldProps} />
        )
      )}
    </MyList>
  );
}
