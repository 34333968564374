import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  TextField,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {
  useNotify,
  useUnselectAll,
  useDataProvider,
  useListContext,
  useRefresh,
} from "react-admin";
import { styled } from "@mui/system";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogTitle-root": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(2),
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const BulkUpdateStatusButton = ({ resource, statusChoices }) => {
  const [open, setOpen] = useState(false);
  const [statusId, setStatusId] = useState("");
  const [recipientId, setRecipientId] = useState("");
  const { selectedIds, onUnselectItems } = useListContext();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    if (selectedIds.length === 0) {
      notify("No items selected", { type: "warning" });
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setStatusId("");
    setRecipientId("");
  };

  const handleStatusChange = (event) => {
    setStatusId(Number(event.target.value));
  };

  const handleRecipientIdChange = (event) => {
    setRecipientId(event.target.value);
  };

  const handleBulkUpdate = async () => {
    try {
      await Promise.all(
        selectedIds.map(async (id) => {
          const { data: currentPackage } = await dataProvider.getOne(resource, {
            id,
          });

          const updateData = {
            statusId: statusId,
            senderId: currentPackage.senderId,
          };

          if (statusId === 2 && recipientId) {
            updateData.recipientId = recipientId;
          }

          return dataProvider.update(resource, {
            id,
            data: updateData,
            previousData: currentPackage,
          });
        })
      );
      notify("Status updated successfully", { type: "success" });
      onUnselectItems(selectedIds); // Clear selections
      unselectAll(resource);
      refresh(); // Refresh the list
      handleClose();
    } catch (error) {
      notify("Error updating status", { type: "error" });
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
        disabled={selectedIds.length === 0}
      >
        Update Package Status
      </Button>
      <StyledDialog
        open={open}
        onClose={handleClose}
        fullScreen={fullScreen}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Update Status for {selectedIds.length} Package(s)
        </DialogTitle>
        <DialogContent>
          <Box my={2}>
            <Typography variant="subtitle1" gutterBottom>
              Choose a new status:
            </Typography>
            <StyledSelect
              value={statusId}
              onChange={handleStatusChange}
              fullWidth
              variant="outlined"
            >
              {statusChoices.map((choice) => (
                <MenuItem key={choice.id} value={choice.id}>
                  {choice.name}
                </MenuItem>
              ))}
            </StyledSelect>
          </Box>
          {statusId === 2 && (
            <StyledTextField
              value={recipientId}
              onChange={handleRecipientIdChange}
              label="Recipient ID"
              fullWidth
              variant="outlined"
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Cancel
          </Button>
          <Button
            onClick={handleBulkUpdate}
            color="primary"
            variant="contained"
            disabled={!statusId}
          >
            Update Status
          </Button>
        </DialogActions>
      </StyledDialog>
    </>
  );
};

BulkUpdateStatusButton.propTypes = {
  resource: PropTypes.string.isRequired,
  statusChoices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default BulkUpdateStatusButton;
