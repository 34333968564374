import React from "react";
import {
  TextField,
  NumberField,
  BooleanField,
  TabbedShowLayout,
} from "react-admin";
import {
  LabeledColumn,
  TitledShow,
} from "../../_myBase/components/titledRecord";
import { Stack } from "@mui/material";
import LocationList from "../locations/LocationList";
import { useParams } from "react-router-dom";

export default function PricingPlanShow() {
  const id = parseInt(useParams()["id"]);

  return (
    <TitledShow>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Summary">
          <Stack spacing={4} direction="row">
            <LabeledColumn>
              <TextField disabled label="Id" source="id"></TextField>
              <TextField source="name"></TextField>
              <NumberField source="small" />
              <NumberField source="medium" />
              <NumberField source="mediumPlus" />
              <NumberField source="large" />
              <NumberField source="extraLarge" />
              <BooleanField source="isDefault" />
            </LabeledColumn>
          </Stack>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Locations" path="locations">
          <LocationList filter={{ pricing_plan_id: id }} />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </TitledShow>
  );
}
