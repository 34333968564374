import React from "react";
import { Edit, SimpleForm, NumberInput, TextInput } from "react-admin";

export default function HostTowersEdit(props) {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput disabled label="Id" source="id"></TextInput>
        <NumberInput source="userId"></NumberInput>
        <TextInput source="towerId"></TextInput>
      </SimpleForm>
    </Edit>
  );
}
