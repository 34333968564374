import React from "react";
import { TextField, DateField, FileField } from "react-admin";
import { MyList } from "../../_myBase/components/myList";

export default function TransactionsList(props) {
  return (
    <MyList resource="transactions" {...props}>
      <TextField source="id" sortable={false} />
      <TextField source="packageId" sortable={false} label="Pakcage Id" />
      <TextField source="discount" sortable={false} />
      <TextField source="amount" sortable={false} />
      <TextField source="state" sortable={false} />
      <DateField source="createdAt" label="Created" sortable={false} />
      <TextField
        source="paymentMethod.paymentProcessor"
        sortable={false}
        label="Gateway used"
      />
      <TextField source="externalId" sortable={false} label="External Id" />
      <FileField source="invoicePdf" title="pdf" />
    </MyList>
  );
}
