import React from "react";
import { TextInput } from "react-admin";
import { MyCreate } from "../../_myBase/components/myMutator";
import { UserInput } from "../users/fields";
import { PackageStatusInput } from "../package_statuses/fields";

export default function PackageCreate(props) {
  return (
    <MyCreate {...props}>
      <UserInput source="senderId" />
      <UserInput source="recipientId" />
      <PackageStatusInput source="statusId" />
      <TextInput source="name"></TextInput>
      <TextInput source="accessCode"></TextInput>
    </MyCreate>
  );
}
