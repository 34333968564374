import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  ImageField,
  UrlField,
} from "react-admin";

export default function PackagePictureShow(props) {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="packageId" />
        <ImageField source="url" label="Image" />
        <UrlField source="url" target="_blank" />
        <TextField source="pictureType" />
      </SimpleShowLayout>
    </Show>
  );
}
