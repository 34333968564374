import React from "react";
import {
  SimpleForm,
  TextField,
  NumberField,
  ReferenceInput,
  Edit,
  AutocompleteInput,
  TabbedShowLayout,
} from "react-admin";
import {
  LabeledColumn,
  TitledShow,
} from "../../_myBase/components/titledRecord";
import { Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import FavoriteLocationsList from "../favorite_locations/FavoriteLocationsList";

export default function LocationShow(props) {
  console.log(props);
  const id = parseInt(useParams()["id"]);

  return (
    <TitledShow>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Summary">
          <Stack spacing={4} direction="row">
            <LabeledColumn>
              <TextField disabled label="Id" source="id"></TextField>
              <TextField source="name" />
              <NumberField source="address1" />
              <NumberField source="lat" />
              <NumberField source="lon" />
            </LabeledColumn>
          </Stack>
          <Edit {...props}>
            <SimpleForm>
              <ReferenceInput
                label="Pricing Plan"
                source="additionalProperties.pricingPlan.id"
                reference="pricing-plans"
              >
                <AutocompleteInput optionText="name" optionValue="id" />
              </ReferenceInput>
            </SimpleForm>
          </Edit>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab
          label="Favorite Locations"
          path="favorite-locations"
        >
          <FavoriteLocationsList filter={{ locationId: id }} />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </TitledShow>
  );
}
