import {
  MyReferenceField,
  MyReferenceInput,
} from "../../_myBase/components/myReference";

export const UserInput = (props) => {
  return <MyReferenceInput reference="users" primaryField="email" {...props} />;
};

export const UserField = (props) => {
  return <MyReferenceField reference="users" primaryField="email" {...props} />;
};
