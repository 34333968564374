import React from "react";
import {
  TextField,
  DateField,
  ImageField,
  TabbedShowLayout,
} from "react-admin";
import { PackageStatusField } from "../package_statuses/fields";
import { UserField } from "../users/fields";
import {
  LabeledColumn,
  TitledShow,
} from "../../_myBase/components/titledRecord";
import { Stack } from "@mui/material";
import PackageHistoryList from "../package_history/PackageHistoryList";
import { useParams } from "react-router-dom";

export default function PackageShow() {
  const id = parseInt(useParams()["id"]);

  return (
    <TitledShow>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Summary">
          <Stack spacing={4} direction="row">
            <LabeledColumn>
              <TextField source="id" />
              <TextField source="name" />
              <PackageStatusField source="statusId" />
              <TextField source="lockerId" />
              <TextField source="lockerType" />
              <TextField source="lockerName" />
              <TextField source="locationName" />
              <UserField source="senderId" />
              <UserField source="recipientId" />
              <DateField source="dropoffTime" />
              <DateField source="pickupTime" />
              <ImageField source="images" src="url" />
            </LabeledColumn>
          </Stack>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="History" path="history">
          <PackageHistoryList filter={{ packageId: id }} />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </TitledShow>
  );
}
