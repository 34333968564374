import React from "react";
import { TextInput } from "react-admin";
import { MyCreate } from "../../_myBase/components/myMutator";
import { UserInput } from "../users/fields";

export default function AddressCreate(props) {
  return (
    <MyCreate {...props}>
      <UserInput source="ownerId" />
      <TextInput source="address1"></TextInput>
      <TextInput source="address2"></TextInput>
      <TextInput source="zipcode"></TextInput>
      <TextInput source="state"></TextInput>
      <TextInput source="country"></TextInput>
      <TextInput source="address_type"></TextInput>
    </MyCreate>
  );
}
