import React from "react";
import { TextField } from "react-admin";
import { MyList } from "../../_myBase/components/myList";
import { UserField } from "../users/fields";

export default function AddressList(props) {
  return (
    <MyList resource="addresses" {...props}>
      <TextField source="id" />
      <UserField source="ownerId" />
      <TextField source="address1" />
      <TextField source="address2" />
      <TextField source="zipcode" />
      <TextField source="country" />
      <TextField source="addressType" />
    </MyList>
  );
}
