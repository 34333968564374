import { Card, CardContent } from "@mui/material";
import { Title } from "react-admin";
import { Settings } from "../utils/settings";
import { getUnsourcedInputs } from "./unsourcedInputs";

export const AppSettings = () => {
  const setups = Settings.get();
  return (
    <Card>
      <Title title="App Settings" />
      <CardContent>{getUnsourcedInputs(setups)}</CardContent>
    </Card>
  );
};
