import {
  MyReferenceField,
  MyReferenceInput,
} from "../../_myBase/components/myReference";

export const LocationInput = (props) => {
  return (
    <MyReferenceInput
      reference="locations"
      secondaryField="address1"
      {...props}
    />
  );
};

export const LocationField = (props) => {
  return (
    <MyReferenceField
      reference="locations"
      secondaryField="address1"
      {...props}
    />
  );
};
