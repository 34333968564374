import React from "react";
import { TextInput, NumberInput } from "react-admin";
import { MyCreate } from "../../_myBase/components/myMutator";

export default function PackagePictureCreate(props) {
  return (
    <MyCreate {...props}>
      <TextInput source="url"></TextInput>
      <TextInput source="picture_type"></TextInput>
      <NumberInput source="package_id"></NumberInput>
    </MyCreate>
  );
}
