import {
  MyReferenceField,
  MyReferenceInput,
} from "../../_myBase/components/myReference";

export const PackageStatusInput = (props) => {
  return <MyReferenceInput reference="package-statuses" {...props} />;
};

export const PackageStatusField = (props) => {
  return <MyReferenceField reference="package-statuses" {...props} />;
};
