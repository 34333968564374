import React from "react";
import { TextInput, BooleanInput } from "react-admin";
import { MyEdit } from "../../_myBase/components/myMutator";

export default function UserEdit(props) {
  return (
    <MyEdit {...props}>
      <TextInput disabled label="Id" source="id"></TextInput>
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="email" />
      <BooleanInput source="emailValidated" />
      <TextInput source="phoneNumber" />
      <BooleanInput source="phoneValidated" />
      <BooleanInput source="isActive" />
      <BooleanInput source="isHost" />
      <BooleanInput source="isSuperuser" />
    </MyEdit>
  );
}
