import { useState, useEffect } from "react";
import { useDataProvider, useNotify } from "react-admin";

const useFieldPreferences = (resource, allFields) => {
  const [hiddenFields, setHiddenFields] = useState([]);
  const [loading, setLoading] = useState(true);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  useEffect(() => {
    const fetchHiddenFields = async () => {
      try {
        const { data } = await dataProvider.getOne("user-preferences", {
          id: resource,
        });
        const hiddenFieldsFromApi = data.hiddenFields || [];
        setHiddenFields(hiddenFieldsFromApi);
      } catch (error) {
        notify("Error fetching preferences", "warning");
      } finally {
        setLoading(false);
      }
    };

    fetchHiddenFields();
  }, [resource, dataProvider, notify]);

  const updatePreference = async (updatedHiddenFields) => {
    try {
      await dataProvider.update("user-preferences", {
        id: resource,
        data: { hidden_fields: updatedHiddenFields },
      });
      setHiddenFields(updatedHiddenFields);
      notify("Updated preferences successfuly", { type: "success" });
    } catch (error) {
      notify("Error updating preferences", { type: "warning" });
    }
  };

  const visibleFields = allFields.filter(
    (field) => !hiddenFields.includes(field)
  );

  return { visibleFields, loading, updatePreference };
};

export default useFieldPreferences;
