import React from "react";
import { TextField } from "react-admin";
import { MyList } from "../../_myBase/components/myList";
import { UserField } from "../users/fields";
import { LocationField } from "../locations/fields";

export default function FavoriteLocationsList(props) {
  return (
    <MyList resource="favorite-locations" {...props}>
      <TextField source="id" />
      <UserField source="userId" />
      <LocationField source="locationId" />
    </MyList>
  );
}
