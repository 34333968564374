import React from "react";
import { Admin, CustomRoutes, Resource } from "react-admin";
import { dataProvider } from "./components/DataProvider";
import MyLayout from "./_myBase/theme/index";

import UserIcon from "@mui/icons-material/People";
import Box from "@mui/icons-material/Book";
import AttachMoneyOutlined from "@mui/icons-material/AttachMoneyOutlined";
import Image from "@mui/icons-material/Image";
import LocalAtm from "@mui/icons-material/LocalAtm";

import AuthProvider from "./components/AuthProvider";
import UsersList from "./components/users/UsersList";
import AddressList from "./components/adrresses/AddressList";
import UserCreate from "./components/users/UserCreate";
import UserEdit from "./components/users/UserEdit";
import UserShow from "./components/users/UserShow";
import AddressCreate from "./components/adrresses/AddressCreate";
import AddressEdit from "./components/adrresses/AddressEdit";
import AddressShow from "./components/adrresses/AddressShow";
import FavoriteLocationsList from "./components/favorite_locations/FavoriteLocationsList";
import FavoriteLocationsCreate from "./components/favorite_locations/FavoriteLocationsCreate";
import FavoriteLocationsEdit from "./components/favorite_locations/FavoriteLocationsEdit";
import LocationList from "./components/locations/LocationList";
import LocationShow from "./components/locations/LocationShow";
import PackagesList from "./components/packages/PackagesList";
import PackageCreate from "./components/packages/PackageCreate";
import PackageEdit from "./components/packages/PackageEdit";
import PackageShow from "./components/packages/PackageShow";
import PackageStatusesList from "./components/package_statuses/PackageStatusesList";
import PackageStatusesCreate from "./components/package_statuses/PackageStatusesCreate";
import PackagePicturesList from "./components/packages_pictures/PackagePicturesList";
import PackagePictureCreate from "./components/packages_pictures/PackagePictureCreate";
import PackagePictureShow from "./components/packages_pictures/PackagePictureShow";
import PricingPlanList from "./components/pricing_plans/PricingPlanList";
import PricingPlanCreate from "./components/pricing_plans/PricingPlanCreate";
import PricingPlanShow from "./components/pricing_plans/PricingPlanShow";
import TransactionsList from "./components/transactions/TransactionsList";
import PricingPlanEdit from "./components/pricing_plans/PriingPlanEdit";
import { myCustomRoutes } from "./_myBase/components/customRoutes";
import PackageStatusesShow from "./components/package_statuses/PackageStatusesShow";
import PackageHistoryList from "./components/package_history/PackageHistoryList";
import HostTowersList from "./components/host_towers/HostTowersList";
import HostTowersCreate from "./components/host_towers/HostTowersCreate";
import HostTowersEdit from "./components/host_towers/HostTowersEdit";
import HostTowersShow from "./components/host_towers/HostTowersShow";

function App() {
  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={AuthProvider}
      layout={MyLayout}
    >
      <Resource
        name="users"
        list={UsersList}
        create={UserCreate}
        edit={UserEdit}
        show={UserShow}
        icon={UserIcon}
      />
      <Resource
        name="packages"
        list={PackagesList}
        create={PackageCreate}
        edit={PackageEdit}
        show={PackageShow}
        icon={Box}
      />
      <Resource
        name="transactions"
        list={TransactionsList}
        icon={AttachMoneyOutlined}
        options={{
          group: "legacy",
        }}
      />
      <Resource
        name="addresses"
        list={AddressList}
        create={AddressCreate}
        edit={AddressEdit}
        show={AddressShow}
        options={{
          group: "legacy",
        }}
      />
      <Resource name="locations" list={LocationList} show={LocationShow} />
      <Resource
        name="favorite-locations"
        list={FavoriteLocationsList}
        create={FavoriteLocationsCreate}
        edit={FavoriteLocationsEdit}
        options={{
          group: "legacy",
        }}
      />
      <Resource
        name="package-statuses"
        list={PackageStatusesList}
        create={PackageStatusesCreate}
        show={PackageStatusesShow}
      />
      <Resource
        name="packages-pictures"
        list={PackagePicturesList}
        create={PackagePictureCreate}
        show={PackagePictureShow}
        icon={Image}
        options={{
          group: "legacy",
        }}
      />
      <Resource
        name="packages-history"
        list={PackageHistoryList}
        icon={Image}
        options={{
          group: "legacy",
        }}
      />
      <Resource
        name="pricing-plans"
        list={PricingPlanList}
        edit={PricingPlanEdit}
        show={PricingPlanShow}
        create={PricingPlanCreate}
        icon={LocalAtm}
      />
      <CustomRoutes>{myCustomRoutes()}</CustomRoutes>
      <Resource
        name="host-towers"
        list={HostTowersList}
        create={HostTowersCreate}
        edit={HostTowersEdit}
        show={HostTowersShow}
      />
      <Resource name="locations" list={LocationList} show={LocationShow} />
    </Admin>
  );
}

export default App;
